

































































import { orgFileUpload, addDataOfExcelOrg } from '@/interfaces/service/org/enterpriseMang'
import { Vue, Component , Prop, Watch } from 'vue-property-decorator'
import { personFileUpload, addDataOfExcelPerson } from '../../../interfaces/service/person/userAuthority'
import _ from 'lodash'


@Component({})
export default class CsvUploadModal extends Vue {
  @Prop({required: true, default: false}) public csvUpLoadModelShow!: boolean// 显示上传model
  @Prop({required: true, default: false}) public confirmLoading!: boolean
  @Prop({required: true, default: ''}) public csvTemplateType!: string
  public theRanterIDs = sessionStorage.getItem('ranterId')
  public csvUploadfileList: any = []  // CSV 文件上传
  public csvCoverUpdate : boolean = false// csv覆盖更新FLAG
  public uploadFileAccept : string = '.xlsx'// 允许上传的格式
  public errorMsgInfo : any = null // 错误信息
  public csvFileList : any = {}
  public loading : boolean = false
  public showOrgCode: boolean = this.$store.state.main.enterprise.showOrgCode
  @Watch('$store.state.main.enterprise.showOrgCode') public watchShowOrgCode() {
    this.showOrgCode = this.$store.state.main.enterprise.showOrgCode
  }
  private async  created() {
    if (this.csvTemplateType === 'person') {
      this.uploadFileAccept = '.xlsx,.zip'
    } else if (this.csvTemplateType === 'group') {
      this.uploadFileAccept = '.xlsx'
    }
  }
  public csvUpLoadModelCancel() {
    const returnData : any = {}
    returnData.close = true
    this.errorMsgInfo = null
    this.csvUploadfileList = []
    this.csvCoverUpdate = false
    this.csvFileList = {}
    this.$emit('getReturn', returnData)
  }
  public async csvUpLoadModelOK() {
    this.errorMsgInfo = null
    const returnData : any = {}
    if (this.csvFileList && (this.csvFileList.file || this.csvFileList.excel_file)) {
      this.loading = true
      try {
        if (this.csvTemplateType === 'person') {
          await addDataOfExcelPerson(this.theRanterIDs, this.csvFileList.file)
        } else if (this.csvTemplateType === 'group') {
          await addDataOfExcelOrg(this.theRanterIDs, this.csvFileList.excel_file)
        }
      } catch (e) {
        console.log(e)
      }
      this.loading = false
      const result = this.$store.state.main.messagePro
      if (result) {
        if (!result.error) {
          if (result.length === 0) {
            this.$message.error(this.$i18n.tc(`csv.upload.modal.template.null.error`))
            return
          }
          this.$message.success(this.$i18n.tc(`user.authority.save.success`))
          returnData.close = false
          this.csvUploadfileList = []
          this.csvCoverUpdate = false
          this.$emit('getReturn', returnData)
        } else {
          this.$message.error(this.$i18n.tc(`csv.upload.modal.template.errorTip`))
          const errorArry = result.data.detail
          if (errorArry && errorArry.length > 0) {
            let errorMsgInfo: string = ''
            const that = this
            if (this.csvTemplateType === 'person') {
              errorMsgInfo += this.$i18n.tc(`csv.upload.modal.template.errorcsv.lineno`) + ',' + this.$i18n.tc(`csv.upload.modal.template.errorcsv.errormsg`) + '\n'
              _.forEach(errorArry, (err) => {
                let errMsg = err.line_num + ','
                if (err.error === 'Lack required fields') {
                  errMsg += that.$i18n.tc(`csv.upload.modal.template.errorcsv.errormsg1`)
                } else if (err.error === 'Organization Code not exist') {
                  errMsg += that.$i18n.tc(`csv.upload.modal.template.errorcsv.errormsg2`)
                } else if (err.error === 'Images not exist or invalid') {
                  errMsg += that.$i18n.tc(`csv.upload.modal.template.errorcsv.errormsg3`)
                }
                errorMsgInfo += errMsg + '\n'
              })
            } else if (this.csvTemplateType === 'group') {
              errorMsgInfo += 'Organization Code,Organization Name,' + this.$i18n.tc(`csv.upload.modal.template.errorcsv.errormsg`) + '\n'
              _.forEach(errorArry, (err) => {
                let errMsg = err['Organization Code'] + ',' + err['Organization Name'] + ','
                if (err.error === 'dataInvalid') {
                  errMsg += that.$i18n.tc(`csv.upload.modal.template.errorcsv.errormsg4`)
                } else if (err.error === 'Name or Code repeat') {
                  errMsg += that.$i18n.tc(`csv.upload.modal.template.errorcsv.errormsg5`)
                }
                errorMsgInfo += errMsg + '\n'
              })
            }
            this.errorMsgInfo = errorMsgInfo
          }
        }
      }
    } else {
      this.csvUpLoadModelCancel()
    }
  }
  public async downloadErrorMsg() {
    const fileDownload : Blob = new Blob([this.errorMsgInfo], {type: 'text/plain;charset=utf-8;'})
    const urlObject: any = window.URL || window.webkitURL || window
    const url : any = urlObject.createObjectURL(fileDownload)
    const el = document.createElement('a')
    el.href = url
    el.download = this.$i18n.tc('csv.upload.modal.template.errorcsv.errormsg') + '.csv'
    el.click()
    urlObject.revokeObjectURL(url)
  }
  // csv文件上传
  public async csvUploadFunc(info) {
    this.errorMsgInfo = null
    if (info.fileList.length > 1) {
      this.csvUploadfileList = info.fileList.slice(-1)
    } else {
      this.csvUploadfileList = info.fileList
    }
    if (this.csvUploadfileList.length > 0) {
      let accessFlag : boolean = true
      if (this.csvTemplateType === 'person') {
        await personFileUpload(info.file, info.fileList.length, this.theRanterIDs).then((result : any) => {
          this.csvFileList = result
        }).catch(() => {
          accessFlag = false
          this.csvFileList = {}
          this.csvUploadfileList = []
          this.$message.error(this.$i18n.tc(`csv.upload.modal.template.upload.error`))
        })
      } else {
        await orgFileUpload(info.file, info.fileList.length, this.theRanterIDs).then((result : any) => {
          this.csvFileList = result
        }).catch(() => {
          accessFlag = false
          this.csvFileList = {}
          this.csvUploadfileList = []
          this.$message.error(this.$i18n.tc(`csv.upload.modal.template.upload.error`))
        })
      }
      if (accessFlag) {
        this.csvFileList = this.$store.state.main.messagePro
      }
    }
  }
}

