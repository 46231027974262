


















































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'

import {
  getRanterTreeData,
  deleteOne,
  deleteAll,
  csvFileOut,
  getOrgInfo,
  updateMultiOrg, getDirectlyAttrNum, delOrg
} from '../../../interfaces/service/org/enterpriseMang'
import _, { isLength, lte, parseInt } from 'lodash'
import moment from 'moment'
import newORGModel from '../enterprise/NewAddModel.vue'
import GuilderHeadButton from '../../../views/main/bottom_guild/GuilderHeadButton.vue'
import svgDownload from '../../../components/svg_saves/user_authority_show/Download.vue'
import svgDelete from '../../../components/svg_saves/user_authority_show/Delete.vue'
import svgUpload from '../../../components/svg_saves/user_authority_show/UpLoad.vue'
import { updateOrg } from '../../../interfaces/service/admin/enterpriseDetail'
import Menu from '../../../components/Menu'
import CsvUploadModal from '../other/CsvUploadModal.vue'
import { breadthSearch, createHash, flatten, getAllSubOrgIds, getTreeDepth, isHasChildren } from '../../../utils/util'

type MoveType = 'up' | 'down'

let rowIDSelect: any = []
@Component({
  components: { newORGModel, svgDownload, svgDelete, GuilderHeadButton, Menu, CsvUploadModal },
  data() {
    return {
      editable: false,
      // 表格edit状态
      searchText: '',
      searchedColumn: null,
      searchInput: null,
      svgDownload,
      svgDelete,
      svgUpload,
    }
  },

  methods: {
    details(param) {
      // todo
      // 将点击过的链接存入顶部tags
      let headTags: any = this.$store.state.main.headTagsave
      if (headTags) {
        headTags.push({
          tagName: this.$i18n.tc('enterprise.management.org_management') + 'ID:' + param,
          name: 'UserDetail',
          params: { id: param },
        })
      } else {
        headTags = [
          {
            tagName: this.$i18n.tc('enterprise.management.org_management') + '_ID:' + param,
            name: 'UserDetail',
            params: { id: param },
          }
        ]
      }

      this.$store.state.main.headTagsave = _.uniqWith(headTags, _.isEqual)
      // 跳转
      this.$router.push({ name: 'UserDetail', params: { id: param } })
    },
    // 表格操作 --
    async deleteU(id) {
      deleteOne(id)
      await getRanterTreeData(this.$store.state.main.userProfile.user_type)
    },
    // expandIcon(props) {
    //   const {
    //     expandable,
    //     prefixCls,
    //     onExpand,
    //     needIndentSpaced,
    //     expanded,
    //     record
    //   } = props;
    //   if (expandable && record.children) {
    //     const expandClassName = expanded ? "expanded" : "collapsed";
    //     // <span class={`${prefixCls}-expand-icon ${prefixCls}-${expandClassName}`}/>
    //     return <a-icon type="user" onClick={onExpand} />;
    //   } else if (needIndentSpaced) {
    //     return <span class={`${prefixCls}-expand-icon ${prefixCls}-spaced`} />;
    //   }
    // }
  },
  computed: {
    columns: {
      get() {
        const columns: any = [
          {
            title: '',
            key: 'menu',
            scopedSlots: { customRender: 'menu' },
            width: 30
          },
          {
            title: this.$i18n.tc('enterprise.management.org_name'),
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            ellipsis: true,
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'editable',
            }
          },
          // {
          //   title: this.$i18n.tc('enterprise.management.org_id'),
          //   dataIndex: 'id',
          //   key: 'id',
          //   width: '15%',
          //   scopedSlots: {
          //     filterDropdown: 'filterDropdown',
          //     filterIcon: 'filterIcon'
          //   },
          // },
          {
            title: this.$i18n.tc('enterprise.management.person_num'),
            dataIndex: 'person_num',
            key: 'person_num',
            width: '20%',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon'
            }
          },
          {
            title: this.$i18n.tc('enterprise.management.create_date'),
            dataIndex: 'created_date',
            key: 'created_date',
            width: '20%',
            scopedSlots: {
              filterDropdown: 'TimeDropdown',
              filterIcon: 'filterIcon'
            },
            onFilter: (value, record) =>
              record.created_date
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
            customRender: (val) => moment(val).format('YYYY-MM-DD HH:mm:ss')
          },
          // {
          //   title: this.$i18n.tc('enterprise.management.parent_id'),
          //   dataIndex: 'parent_id',
          //   key: 'parent_id',
          //   scopedSlots: {
          //     filterDropdown: 'filterDropdown',
          //     filterIcon: 'filterIcon',
          //     customRender: 'customRender',
          //   },
          //   onFilter: (value, record) =>
          //     record.parent_id
          //       .toString()
          //       .toLowerCase()
          //       .includes(value.toLowerCase()),
          // },
          {
            title: '',
            key: 'link',
            width: 200,
            scopedSlots: { customRender: 'action' },
          }
        ]
        if (this.$store.state.main.enterprise.showOrgCode) {
          columns.splice(2, 0, {
            title: this.$i18n.tc('enterprise.management.org_code'),
            dataIndex: 'code',
            key: 'code',
            width: '20%',
            ellipsis: true
          })
        }
        return columns
      },
    },

    totalPage: {
      get() {
        const countList =
          this.$store.state.main.enterprise.total_page *
          (this.$store.state.main.enterprise.limit ? this.$store.state.main.enterprise.limit : 7)
        return countList // count * from table to do
      },
    },
  },
})
export default class EnterpriseMangerShow extends Vue {
  public deleteEmptyOrg: boolean = true
  public deleteOrgOrChange: number = 0
  public delOrgInfo: any = {}
  public orgList: any = []
  public delOrgMoveID: any = 0
  public showOrgCode: boolean = this.$store.state.main.enterprise.showOrgCode
  @Watch('$store.state.main.enterprise.showOrgCode') public watchShowOrgCode() {
    this.showOrgCode = this.$store.state.main.enterprise.showOrgCode
  }
  get treeToListdata() {
    this.dataList = []
    this.generateList(this.dataSource)
    const dataList: any = this.dataList
    return dataList
  }

  // 每行的checkbox
  get rowSelection() {
    return {
      getCheckboxProps: (record) => ({
        props: {
          // disabled: record.id === "Disabled User", //需要禁用的行 Column configuration not to be checked
          id: record.id + '',
          children: record.record,
          name: record.name,
        },
      }),
      onChange: (selectedRowKeys, selectedRows) => {
        rowIDSelect = selectedRows.map((obj) => {
          return obj.id
        })
      },
      onSelect: (record, selectedRow, event) => {
        const idIn: any = this.orgNameChildHave.map((e) => {
          return e.id
        })
        if (_.indexOf(idIn, record.id) > -1) {
          _.pullAllWith(this.orgNameChildHave, [record], _.isEqual)
        } else {
          this.orgNameChildHave.push(record)
        }

        this.getDeleteTipOrgName()

        if (this.orgNameChildHave.length === 0) {
          this.orgNameChildHaveFlag = false
        }
      },
      onSelectAll: (selected, selectedRows, changeRows) => {
        if (selected) {
          this.orgNameChildHaveFlag = true
          this.orgNameChildHave = _.cloneDeep(this.dataSource)
          this.getDeleteTipOrgName()
        } else {
          this.orgNameChildHaveFlag = false
          this.orgNameChildHave = []
          this.showDeletOrgNames = []
        }
      },
    }
  }
  get eMangerShow() {
    const orgData = this.$store.state.main.enterprise.enterpriseData.map((e) => {
      if (sessionStorage.getItem('ranterId') === e.id.toString()) {
        return e
      }
    })
    const theORGData = _.compact(orgData)
    return theORGData
  }

  get selectedKeys() : any[] {
    let selectedKeys = this.$store.state.main.enterprise.selectedKeys
    if (_.isEmpty(selectedKeys)) {
      selectedKeys = JSON.parse(localStorage.getItem('selectedKeys') || '[]')
    }
    return selectedKeys
  }

  get dataSource() {
    return this.tableData
  }

  set dataSource(val) {
    this.tableData = val
  }

  /**
   * 是否正在编辑
   */
  get isEditting() {
    return this.editTableModeIndex === -1000 ? false : true
  }

  get enterpriseHashData() {
    return this.$store.state.main.enterprise.enterpriseHashData
  }

  public $refs!: {table: HTMLFormElement, code: HTMLFormElement, name: HTMLFormElement}
  public scrollFix: string = ''
  public tableData: any[] = []
  public tableDataCache: any[] = [] // 表格数缓存，供搜索使用
  public theWindowH = document.body.clientHeight
  public loading: boolean = true
  public userType: number = this.$store.state.main.userProfile.user_type
  // ------弹出对话框-----------
  public visible: boolean = false
  public confirmLoading: boolean = false
  public csvUploadfilelist: any = []
  // ------弹出对话框-----------
  // 表格分页，过滤，排序情况
  public thePagination: any = null
  public theFilters: any = null
  public theSorter: any = { order: null, field: null }
  // 展开tree时触发事件
  public ranterIDCose = Number(sessionStorage.getItem('ranterId'))
  public expandedRowKeys = [this.ranterIDCose]
  // tree serach 前端树搜索
  public searchValue: any = []
  public searchText: string = ''
  public searchDate: string[] = []
  public searchedColumn: any = null
  public dataList: any = []
  // 新增个人资料
  public Newvisible: boolean = false
  // 新增个人资料 END
  public editTableModeIndex: number = -1000
  // delete row selected
  public orgNameChildHave: any = []
  public orgNameChildHaveShow: any = []
  public orgNameChildHaveFlag: boolean = false
  // data return END
  // CSV导入方式

  public csvUpLoadModelShow: boolean = false // 显示上传model
  public csvUpLoadModelLoading: boolean = false // 加载显示
  // CSV 文件上传
  public csvFileURL: string = ''
  public csvUploadfileList: any = []

  // Menu
  public menuVisible: boolean = false
  public offsetX: string = ''
  public offsetY: string = ''
  public hasPrev: boolean = true
  public hasNext: boolean = true
  public deleteModalVisible: boolean = false
  public selectedOrg: any = {}
  public orgEditInfo: any = {}
  public modalFlag: string = 'insert'
  public form: any = { name: '', code: '', trueCode: '' , id: '', beforeModify: ''}

  public resetConformFlag: boolean = false
  public showDeletOrgNames: string[] = []

  @Watch('$store.state.main.screen.height') public windowHeight() {
    this.theWindowH = document.body.clientHeight
  }
  // 展开事件反馈 TODO
  public expandedChange(expandedRowIDS) {
    // TODO
  }

  /**
   * 表格搜索
   */
  public handleTreeSearch(dataIndex, value) {
    // 还原搜索结果
    this.dataSource = _.cloneDeep(this.tableDataCache)

    const nodeList = this.dataSource

    if (nodeList && nodeList.length > 0) {
      nodeList.forEach((item) => {
        this.searchEach(item, value, dataIndex)
      })

      // 没有叶子节点的根节点也要清理掉
      const length = nodeList.length
      for (let i = length - 1; i >= 0; i--) {
        const e2 = nodeList[i]
        // 防止非string类型调用 indexOf 报错
        e2[dataIndex] = e2[dataIndex].toString()

        if (!isHasChildren(e2) && e2[dataIndex].indexOf(value) <= -1) {
          nodeList.splice(i, 1)
        }
      }
    }
    return
  }

  public searchEach(node, value, dataIndex) {
    const depth = getTreeDepth(node)

    for (let i = 0; i < depth - 1; i++) {
    let spliceCounter = 0

    // 遍历树形结构
    this.traverseTree(node, (item) => {
      if (isHasChildren(item)) {
        const children = item.children
        const length = children.length

        // 找到不匹配搜索内容的叶子节点并删除。为了避免要删除的元素在数组中的索引改变，从后向前循环,
        // 找到匹配的元素就删除。
        for (let j = length - 1; j >= 0; j--) {
          const e3 = children[j]

          // 防止非string类型调用 indexOf 报错
          e3[dataIndex] = e3[dataIndex].toString()

          if (dataIndex === 'created_date') {
            // 时间筛选
            value[0] = value[0] === '' ? undefined : value[0]
            value[1] = value[1] === '' ? undefined : value[1]

            const searchDate = moment(e3[dataIndex]).format('YYYY-MM-DD HH:mm:ss')
            const startDate = moment(value[0]).format('YYYY-MM-DD HH:mm:ss')
            const endDate = moment(value[1]).format('YYYY-MM-DD HH:mm:ss')

            if (!isHasChildren(e3) && !moment(searchDate).isBetween(startDate, endDate)) {
              children.splice(j, 1)
              spliceCounter++
            }
          } else {
            // 非时间筛选
            if (!isHasChildren(e3) && e3[dataIndex].indexOf(value) <= -1) {
              children.splice(j, 1)
              spliceCounter++
            }
          }
        }
      }
    })

    if (spliceCounter === 0) { continue }
    }
  }

  // 非递归遍历树
  public traverseTree(node: any, callback) {
    if (!node) { return }
    const stack: any = []
    stack.push(node)

    let tmpNode
    while (stack.length > 0) {
      tmpNode = stack.pop()
      callback(tmpNode)
      if (tmpNode.children && tmpNode.children.length > 0) {
        for (const item of tmpNode.children) {
          stack.push(item)
        }
      }
    }
  }

  /**
   * 重置搜索
   */
  public searchReset() {
    // this.searchValue['' + dataIndex] = ''
    this.searchText = ''
    this.searchDate = []
    this.expandedRowKeys = [this.ranterIDCose]
    this.dataSource = this.tableDataCache
  }

  public generateList(data) {
    for (const i of data) {
      const node: any = i
      const id: number = node.id
      const parent_id: number = node.parent_id
      const created_date: Date = node.created_date
      this.dataList.push({
        id,
        name: node.name,
        created_date,
        parent_id,
      })
      if (node.children ? node.children.length > 0 : false) {
        this.generateList(node.children)
      }
    }
  }

  public handleCsvUpload() {
    // 管理表格是处于编辑状态并且存在修改 -- 不允许进行操作
    if (this.isEditting && this.form.beforeModify !== (this.form.name + '_' + this.form.code)) {
      this.$store.commit('setIsNoJumping', true)
      return
    }

    this.csvUpLoadModelShow = true
  }
  public csvUpLoadModelCancel() {
    // 确认上传
    this.csvUpLoadModelShow = false
  }
  /**
   * 确认 | 取消 上传CSV
   */
  public async csvUpLoadModelOK(returnData) {
    this.csvUpLoadModelShow = false
    const { close, csvCoverUpdate, csvFileList } = returnData

    // 取消上传
    if (close) { return }
    // // 确认上传 CSV
    // const orgData: any = this.buildCsvUploadData(csvFileList)

    // if (orgData.length === 0) { return }
    // // 获取当前租户的maxSort
    // const currentRanter = this.enterpriseHashData[this.ranterIDCose]
    // const maxSort = currentRanter['max_sort']

    // // 设置 sort
    // for (const index in orgData) {
    //   if (!orgData[index]['parent_name']) {
    //     orgData[index].sort = currentRanter.sort
    //   } else {
    //     orgData[index].sort = maxSort + parseInt(index, 10) + 100
    //   }
    // }

    // // 覆盖更新
    // if (csvCoverUpdate) {
    //   // 删除除根组织外所有组织
    //   const deleteIds = getAllSubOrgIds(this.enterpriseHashData, this.ranterIDCose, false)

    //   await deleteAll(this.userType, deleteIds)
    //   // 进行更新
    //   await updateMultiOrg(this.ranterIDCose, orgData)
    // } else {
    //   // 增量更新
    //   const ranterData = this.enterpriseHashData[this.ranterIDCose]
    //   const ranterHash = {}
    //   createHash([ranterData], ranterHash)

    //   const data = _.map(ranterHash, (item: any) => {
    //     const parentNode = this.enterpriseHashData[item.parent_id]

    //     if (!parentNode) {
    //       return { name: item.name, id: item.id }
    //     }

    //     return {
    //       name: item.name,
    //       id: item.id,
    //       parent_name: (this.enterpriseHashData[item.parent_id]).name
    //     }
    //   })

    //   const uniqedData = orgData.filter((x) => !data.some((item) => x.name === item.name))
    //   await updateMultiOrg(this.ranterIDCose, uniqedData)
    // }
    // 重新渲染表格
    const enterpriseData: any = await getRanterTreeData(this.$store.state.main.userProfile.user_type)
    this.buildTableData(enterpriseData)
    this.tableDataCache = this.dataSource
  }

  // CSV导出方式
  public csvFilesOut() {
    // 管理表格是处于编辑状态并且存在修改 -- 不允许进行操作
    if (this.isEditting && this.form.beforeModify !== (this.form.name + '_' + this.form.code)) {
      this.$store.commit('setIsNoJumping', true)
      return
    }

    const pages = this.thePagination ? this.thePagination.pageSize : 7
    csvFileOut('csv', this.treeToListdata)
  }
  public handleAdd() {
    // 管理表格是处于编辑状态并且存在修改 -- 不允许进行操作
    if (this.isEditting && this.form.beforeModify !== (this.form.name + '_' + this.form.code)) {
      this.$store.commit('setIsNoJumping', true)
      return
    }

    this.Newvisible = true
  }
  public async newOrgModel(obj) {
    this.Newvisible = obj.visable

    const enterpriseData: any = await getRanterTreeData(this.$store.state.main.userProfile.user_type)
    this.buildTableData(enterpriseData)
    this.tableDataCache = this.dataSource
  }
  public editTableMode(recordID) {
    this.editTableModeIndex = recordID
  }

  /**
   * 单行修改 修改后保存
   * @param {any} 被编辑的所在行行数据
   */
  public async editTablesave(target) {
    const { id } = target
    const updatedOrg = await updateOrg(id, { name: this.form.name, code: this.form.code })
    // 同步 childOrgChoseStore 数据
    localStorage.setItem('childOrgChoseStore', JSON.stringify({keys: updatedOrg, pageIndex: this.ranterIDCose}))
    this.editTableModeIndex = -1000

    // 重新渲染表格
    const enterpriseData: any = await getRanterTreeData(this.$store.state.main.userProfile.user_type)
    this.buildTableData(enterpriseData)
    this.tableDataCache = this.dataSource
  }

  /**
   * 单行修改 取消编辑
   */
  public editTablecancel() {
    this.editTableModeIndex = -1000
  }

  // 单行修改 Blur
  public handleBlur() {
    if (this.form.beforeModify !== (this.form.name + '_' + this.form.code)) {
      this.$store.commit('setIsNoJumping', true)
      this.resetConformFlag = true
      return
    }
    this.editTablecancel()
  }

  /**
   * 单行修改 确认取消编辑
   */
  public resetConformOk() {
    this.resetConformFlag = false
    this.$store.commit('setIsNoJumping', false)
    this.editTablecancel()
  }

  /**
   * 不取消编辑
   */
  public resetConformCancel() {
    this.$store.commit('setLastActionType', [''])
    this.resetConformFlag = false
  }

  // 翻页,排序...
  public handleTableChange(pagination, filters, sorter) {
    this.thePagination = pagination
    this.theFilters = filters
    this.theSorter = sorter
    // TODO
    // getRanterListData(
    //   this.userType,
    //   pagination.current,
    //   pagination.pageSize,
    //   sorter.field,
    //   sorter.order,
    //   filters,
    // )
    // window[
    //   'vm'
    // ].cacheData = this.$store.state.main.enterprise.enterpriseData.map(
    //   (item) => ({
    //     ...item,
    //   }),
    // )
    // TODO
  }
  public rowDeleteAll() {
    // 管理表格是处于编辑状态并且存在修改 -- 不允许进行操作
    if (this.isEditting && this.form.beforeModify !== (this.form.name + '_' + this.form.code)) {
      this.$store.commit('setIsNoJumping', true)
      return
    }

    this.visible = !this.visible
    if (rowIDSelect.length === 0) {
      this.visible = false
    }
    // 重新生成orgNameChildHave
    const temp = _.cloneDeep(this.orgNameChildHave)
    this.orgNameChildHave = []
    for (const item of temp) {
      this.orgNameChildHave.push(this.enterpriseHashData[item.id])
    }
    this.orgNameChildHave = _.compact(this.orgNameChildHave)

    this.orgNameChildHaveShow = this.orgNameChildHave.map((enterpriseInfoChose) => {
      if (enterpriseInfoChose.children && enterpriseInfoChose.children.length > 0) {
        this.orgNameChildHaveFlag = true
        return enterpriseInfoChose
      }
      return null
    })
    this.orgNameChildHaveShow = _.compact(this.orgNameChildHaveShow)

    this.orgNameChildHaveFlag = this.showDeletOrgNames.length > 0 ? true : false
  }
  // delete row selected model
  public async modelHandleOk() {
    this.orgNameChildHave = '' // clear warning INFO 清空提示消息
    this.confirmLoading = true
    this.visible = false

    // 选择的组织包含子组织时，删除所有子组织
    const deleteIds: any[] = []

    for (const item of rowIDSelect) {
      const tempIds: any[] = getAllSubOrgIds(this.enterpriseHashData, item)
      deleteIds.push(...tempIds)
    }

    await deleteAll(this.userType, [...new Set(deleteIds)]) // rowIDSelect;回传删除的ID数组

    // 删除的是前画面选中的组织
    if (rowIDSelect.includes(this.selectedKeys[0])) {
      // 重置前画面组织 tree 选中状态
      this.$store.commit('setSelectedKeys', [])
      localStorage.setItem('selectedKeys', '[]')
      // 重置前画面选中子组织状态
      localStorage.setItem('childOrgChoseStore', '')
    }

    const enterpriseData = await getRanterTreeData(this.$store.state.main.userProfile.user_type)
    this.buildTableData(enterpriseData)
    this.tableDataCache = this.dataSource
    this.confirmLoading = false
    // 清空 select
    // this.orgNameChildHave = []
  }

  /**
   * 打开Menu
   */
  public handleOpenMenu(e, record) {
    if (this.isEditting && this.form.beforeModify !== (this.form.name + '_' + this.form.code)) {
      this.resetConformFlag = true
      return
    }

    const { id } = record

   // 是否显示 上移，下移
    // this.hoverOrgId = item.id
    this.selectedOrg = record
    const hoverOrgInfo =   this.getHoverOrgInfo(id)

    const { prevOrg , nextOrg } = hoverOrgInfo
    this.hasPrev = !_.isEmpty(prevOrg)
    this.hasNext = !_.isEmpty(nextOrg)

    // Menu 坐标位置计算
    const viewportY = document.body.clientHeight // 浏览器当前视窗高度
    let menuHight = 163 // 整个菜单栏默认高度
    const margin = 6 // 距离点击 ico n的 margin 值
    let offsetX = 0
    let offsetY = 0

    if (!this.hasPrev) {
      menuHight -= 32
    }

    if (!this.hasNext) {
      menuHight -= 32
    }

    this.menuVisible = true
    const rect = e.target.getBoundingClientRect() // 获取点击的dom元素信息
    const { height, x, y } = rect
    const deviation = height / 2 // 点击范围误差值
    offsetY = height + y + margin
    offsetX = x

    // 计算菜单是否超出屏幕
    if (offsetY + menuHight + margin + deviation > viewportY) {
      offsetY = offsetY - 48 - menuHight - margin
    }

    this.offsetX = offsetX + 'px'
    this.offsetY = offsetY + 'px'
  }

  /**
   * 处理关闭菜单
   */
  public handleMenuClose() {
    // this.hoverOrgId = -10
    this.menuVisible = false
  }

  /**
   * 编辑菜单
   */
  public handleEdit() {
    this.menuVisible = false
    this.editTableMode(this.selectedOrg.id)
    // this.form.id = this.selectedOrg.id
    this.form.name = this.selectedOrg.name
    this.form.trueCode = this.selectedOrg.code
    const orgCode = this.selectedOrg.code
    this.form.code = (orgCode && orgCode.length > 1) ? orgCode.substring(orgCode.length - 2) : orgCode
    this.form.beforeModify = this.form.name + '_' + this.form.code
  }

  /**
   * 上移
   */
  public handleMoveUp() {
    this.handleMove('up')
  }

  /**
   * 下移
   */
  public handleMoveDown() {
    this.handleMove('down')
  }

  /**
   * 新建组织
   */
  public handleCreate() {
    this.Newvisible = true
    this.menuVisible = false
    this.modalFlag = 'insert'
    this.orgEditInfo = {name: '', parent_id: this.selectedOrg.id}
  }

  /**
   * 删除
   */
  public handleDelete() {
    this.menuVisible = false
    // 查询出当前删除的组织 下的情况
    const deleteId = this.selectedOrg.id
    getDirectlyAttrNum(deleteId)
    .then( (res: any) => {
      this.delOrgInfo = res
      this.deleteEmptyOrg = (res.sub_orgs + res.persons + res.devices) === 0
      this.deleteModalVisible = true
      this.delOrgMoveID = deleteId
      this.orgList = []
      this.generateOrgList(this.$store.state.main.enterprise.enterpriseData, deleteId)
    }).catch(() => {
        this.deleteModalVisible = false
    })
  }



  public deleteModalOk() {
    const enterpriseHashData: any = this.$store.state.main.enterprise.enterpriseHashData
    const { id } = this.selectedOrg
    const moveID = this.deleteOrgOrChange === 0 ? 0 : this.delOrgMoveID
    const deleteId = id

    delOrg(deleteId, moveID).then( () => {
      this.$message.success(`${this.$i18n.tc('delete')}${ enterpriseHashData[deleteId].name }${this.$i18n.tc('success')}`)
      this.deleteModalVisible = false
      // 重新设置选中, 将被删除的id从选中id中删除
      rowIDSelect = rowIDSelect.filter((selectId) => deleteId !== selectId)

      // 删除的是前画面选中的组织
      if (deleteId === this.selectedKeys[0]) {
        // 重置前画面组织 tree 选中状态
        this.$store.commit('setSelectedKeys', [])
        localStorage.setItem('selectedKeys', '[]')
        // 重置前画面选中子组织状态
        localStorage.setItem('childOrgChoseStore', '')
      }

      // 删除成功，重新获取 Tree 数据
      getRanterTreeData(this.$store.state.main.userProfile.user_type)
      .then( (res) => {
        // 重新构建表格数据
        this.buildTableData(res)
        this.tableDataCache = this.dataSource
        this.getDeleteTipOrgName()
      })


    }).catch(() => {
        this.$message.error(
          `${this.$i18n.tc('delete')} ${ enterpriseHashData[deleteId].name } ${this.$i18n.tc('failed')}`
          )
        this.deleteModalVisible = false
      })
  }

  /**
   * deleteModalOk
   */
  public deleteModalOk1() {
    const { id } = this.selectedOrg

    const enterpriseHashData: any = this.$store.state.main.enterprise.enterpriseHashData
    const flattened = flatten(enterpriseHashData[id].children)
    const fattenedId = _.map(flattened, (item) => {
      return item.id
    })
    const deleteId = id

    deleteAll(this.$store.state.main.userProfile.user_type, [id, ...fattenedId])
    .then( () => {
      this.$message.success(`${this.$i18n.tc('delete')}${ enterpriseHashData[deleteId].name }${this.$i18n.tc('success')}`)
      this.deleteModalVisible = false
      // 重新设置选中, 将被删除的id从选中id中删除
      rowIDSelect = rowIDSelect.filter((selectId) => deleteId !== selectId)

      // 删除的是前画面选中的组织
      if (deleteId === this.selectedKeys[0]) {
        // 重置前画面组织 tree 选中状态
        this.$store.commit('setSelectedKeys', [])
        localStorage.setItem('selectedKeys', '[]')
        // 重置前画面选中子组织状态
        localStorage.setItem('childOrgChoseStore', '')
      }

      // 删除成功，重新获取 Tree 数据
      getRanterTreeData(this.$store.state.main.userProfile.user_type)
      .then( (res) => {
        // 重新构建表格数据
        this.buildTableData(res)
        this.tableDataCache = this.dataSource
        this.getDeleteTipOrgName()

        // 清空 select
        // this.orgNameChildHave = []
      })
    }).catch(() => {
        this.$message.error(this.$i18n.tc(`删除失败`))
        this.deleteModalVisible = false
      })
  }

  /**
   * deleteModalCancle
   */
  public deleteModalCancle() {
    this.deleteModalVisible = false
  }

  /**
   * 获取处于 hover 状态下选中的组织信息
   */
  private getHoverOrgInfo(hoverOrgId) {
    const enterpriseHashData: any = this.$store.state.main.enterprise.enterpriseHashData
    const { id, prev_id, next_id } = enterpriseHashData[hoverOrgId]
    const currentOrg = enterpriseHashData[id]
    const prevOrg = enterpriseHashData[prev_id] || {}
    const nextOrg = enterpriseHashData[next_id] || {}

    return {
      currentOrg,
      prevOrg,
      nextOrg
    }
  }

  /**
   * 处理菜单上移下移
   * @param {MoveType} type 移动类型 up | down
   */
  private handleMove(type: MoveType) {
    const hoverOrgInfo = this.getHoverOrgInfo(this.selectedOrg.id)
    const { currentOrg, prevOrg, nextOrg } = hoverOrgInfo

    let sort: number = 0
    let p1: any = null
    let p2: any = null
    let p3: any = null

    // 上移 -- 更新前一个组织
    if (type === 'up') {
      sort = prevOrg.sort
      p2 = updateOrg(prevOrg.id, {
        parent_id: prevOrg.parent_id,
        sort: currentOrg.sort
      })
    }

    // 下移 -- 更新后一个组织
    if (type === 'down') {
      sort = nextOrg.sort
      p1 = updateOrg(nextOrg.id, {
        parent_id: nextOrg.parent_id,
        sort: currentOrg.sort
      })
    }

    // 更新当前组织
    p3 = updateOrg(currentOrg.id, {
      parent_id: currentOrg.parent_id,
      sort
    })

    Promise.all([p1, p2, p3]).then(() => {
    // 下移操作完成，重新获取treeData`
    getRanterTreeData(this.$store.state.main.userProfile.user_type).then((res) => {
      // 重新构建表格数据
      this.buildTableData(res)
      this.tableDataCache = this.dataSource
      const newHoverOrgInfo = this.getHoverOrgInfo(this.selectedOrg.id)
      // 是否显示上移，下移菜单项
      this.hasPrev = !_.isEmpty(newHoverOrgInfo.prevOrg)
      this.hasNext = !_.isEmpty(newHoverOrgInfo.nextOrg)
    })
  })
  }

  /**
   * 构建表格数据
   */
  private buildTableData(tree) {
    const orgData = tree.map((e) => {
      if (sessionStorage.getItem('ranterId') === e.id.toString()) {
        return e
      }
    })

    // 隐藏根组织显示
    let tableData: any = _.compact(orgData)
    tableData = tableData[0].children

    // 动态添加组织人数
    breadthSearch(tableData, async (item) => {
      const { id } = item
      const res: any =  await getOrgInfo(id)
      this.$set(item, 'person_num', res.persons)
    })
    this.dataSource = tableData
  }

  /**
   * 构建 csv 上传用数据
   */
  private buildCsvUploadData(csvFileList) {
    if (csvFileList.length === 0) { return [] }

    const hash: any = {}
    // 遍历组装组织 table 数据
    for (const item of csvFileList) {
      const orgArr: string[] = item.organization_name.split('/')
      let lastId: string = ''
      let lastNotNullIndex = 0
      for (const index in orgArr) {
        if (Object.hasOwnProperty.call(orgArr, index)) {
          const orgName = orgArr[index]

          // 组织名称长度校验
          if (orgName.length > 20) {
            this.$message.error(this.$i18n.tc('enterprise.management.csv__error_msg.over_length') + orgName)
            return []
          }

          // 组织名为空处理
          if (orgName.length === 0) {
            lastId = orgArr[lastNotNullIndex]
            continue
          }

        // 若已在 hash 中存在
          if (_.has(hash, orgName)) {
            // 记录中没有parent_name, 将本次的parent_name 赋值
            if (!hash[orgName].parent_name && lastId) {
              // 更新parent_name
              hash[orgName].parent_name = lastId
            }
          } else {
            // 没在 hash 中
            if (lastId) {
              hash[orgName] = {name: orgName, parent_name: lastId}
            } else {
                hash[orgName] = {name: orgName}
              }
            }
          lastId = orgName
          lastNotNullIndex  = +index
        }
      }
  }

    const orgData = _.map(hash, ((item) => {
    if (!item.parent_name) {
      item.id = this.ranterIDCose
    }

    return item
  }))

    return orgData
  }

  private getDeleteTipOrgName() {
    let showDeletOrgNames: string[] = []

    for (const id of rowIDSelect) {
      let showOrg = ''
      if (this.enterpriseHashData[id] && this.enterpriseHashData[id].children) {
        // 只取得最上层父级节点
        const hash = _.cloneDeep(this.enterpriseHashData)
        let tempId = id
        while (hash[tempId].parent_id !== 0) {
          if (rowIDSelect.includes(tempId)) {
            showOrg = hash[tempId].name
          }
          tempId = hash[tempId].parent_id
        }
        showDeletOrgNames.push(showOrg)
      }
    }

    showDeletOrgNames = [...new Set(showDeletOrgNames)]
    this.showDeletOrgNames = showDeletOrgNames
  }

  /**
   * 设置表头样式
   */
  private setScrollClass() {
    // 判断是否出滚动条
    const scrollY = this.theWindowH * 0.95 - 100
    const headerHeight = 40
    const clientHeight = this.$refs.table.$el.clientHeight
    let className =  'header-srcoll--hidden'

    if (scrollY < clientHeight - headerHeight) {
      className = 'header-srcoll--show'
    }

    this.scrollFix = className
  }

  // delete row selected
  // VUE 生命周期
  private async created() {
    // 全局点击事件
    document.addEventListener('click', (e) => {
      if (!this.isEditting) { return }
      if (!this.$refs.name) { return }
      if (!this.$refs.code) { return }
      if (this.resetConformFlag)  { return }

      const isNeedCancle = ((e.target as any).getAttribute('data-cancle'))

      if (isNeedCancle === '0') { return }

      this.$refs.name.focus()

      // 是否点击到input框外的元素
      let className: any = (e.target as any).className || ''
      if (_.isObject(className)) { className = '' }

      if (className !== 'ant-input' && !className.match(/^menu-item/)) {
        this.$nextTick(() => {
          this.handleBlur()
        })
      }
    })

    const enterpriseData: any = await getRanterTreeData(this.$store.state.main.userProfile.user_type)

    this.buildTableData(enterpriseData)
    this.tableDataCache = this.dataSource
    this.loading = false
  }

  private updated() {
    // 计算是否出现滚动条，设置表头样式
    setTimeout(() => {
      this.setScrollClass()
    }, 100)
  }
  public generateOrgList(data, delId) {
    for (const i of data) {
      const node: any = i
      if (node.ancestors.split(',').indexOf(delId + '') === -1) {
        this.orgList.push({ id: node.id, name: node.name })
      }
      if (node.children) {
        this.generateOrgList(node.children, delId)
      }
    }
  }
}
