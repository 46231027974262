


































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import _ from 'lodash'
import {
  getTypeDeviceData,
  updateDevice,
  addBlacklist,
  getDeviceWithPersons,
  getDeviceWithPersonsBlacklist
} from '../../../interfaces/service/device/deviceHardWare'
import {
 getTreePersonData
} from '../../../interfaces/service/org/enterpriseMang'
import searchTool from '../other/SearchToolBar.vue'
import { commitSetLoading } from '../../../store/main/mutations'
const pageReturnDatas: any = {}
interface TreeDataItem {
  key: string
  title: string
  flag: string
  disabled?: boolean
  scopedSlots: any
  children?: TreeDataItem[]
}

// 穿梭框左侧树形结构数据(添加disable属性)
function handleTreeData(data: any[], targetKeys: string[] = []): any[] {
  _.forEach(data, (item) => {
    item.key = item.key.toString() + item.flag.toString()
    item.title = item.title.toString()
    item.scopedSlots = { icon: 'custom' }
    item.disabled = targetKeys.includes(item.key.toString())
    if (item.children) {
      handleTreeData(item.children, targetKeys)
    }
  })
  return data
}

@Component({
  components: {
    searchTool
  },
  data() {
    return {
    }
  },
})
export default class DeviceHomeDraw extends Vue {
  @Prop({ required: true })  public userCounter!: number // 部门人数
  @Prop({ required: true })  public orgCounter!: number // 子组织数
  @Prop({ required: true })  public deviceCounter!: number // 部门绑定设备数
  @Prop({ required: true })  public devicedetailDataID!: any // 点击的设备详情
  @Prop() public orgDataId!: any // 当前部门ID
  public name: string = ''
  public deviceFormData : any = {} // 更新设备全部字段详情
  public dataList: any = []
  public subLoading: boolean = false // 提交按钮加载状态
  public theWindowH = document.body.clientHeight
  // 提交用户信息表单数据 formModel
  public userBinds: any = []
  public theRanterIDs = sessionStorage.getItem('ranterId') || '' // orgID chose选中的orgid
  public dataCanBind: any = [] // 可绑定的数据 org 与 person  tree
  public transferDataSource: any = [] // 穿梭框数据源
  public expandedKeys: any = []
  public autoExpandParent : boolean = true
  // 页面数据提交验证报错
  public valNameStatus: string = ''
  public valNameHelp: string = ''
  // 用于穿梭框绑定的数据源 人员&org DATA
  public personWithORG : any = {}
  public personFlag: string = 'person' // 判断用固定字符
  public orgFlag: string = 'org' // 判断用固定字符
  @Watch('$store.state.main.screen.height')  public windowHeight() {
    this.theWindowH = document.body.clientHeight
  }
    // 穿梭框左侧树形结构数据
  get treeDataTransfer() {
    // 穿梭框左侧树形结构数据
    if (this.personWithORG.length > 0) {
      const data = _.cloneDeep(this.personWithORG)
      return handleTreeData(data, this.userBinds)
    }
  }
  // 穿梭框数据源扁平化
  public flatten(list: TreeDataItem[] = [], transData: TreeDataItem[]) {
    const rtn = _.cloneDeep(list)
    _.forEach(rtn, (item) => {
      item.key = item.key.toString() + item.flag.toString()
      item.disabled = false
      transData.push(item)
      if (item.children) {
        this.flatten(item.children, transData)
      }
    })
  }
  // 更新图标
  public changeRightIcon(keys) {
    // 取得穿缩的icon样式
    const iconArr = _.compact(_.map(keys, (item) => {
      return _.find(this.transferDataSource, (trans) => {
        return _.eq(item, trans.key)
      })
    }))
    // 页面渲染完成后刷新图标
    this.$nextTick(() => {
      setTimeout(() => {
        const flags = _.flatMap(iconArr, (item) => {
          return item.flag
        })
        const concatIcon = ' icon-contact_card_regular'
        const teamIcon = ' icon-people_team_regular'
        const concat = ' icon-contact_card'
        const team = ' icon-people_team'
        const transfer: Vue = this.$refs.transfer as Vue
        const ellist = transfer.$el.getElementsByClassName('ant-transfer-list-content-item-text')
        const elarr = _.reject(ellist, (item) => {
          return _.includes(item.className, concatIcon) || _.includes(item.className, teamIcon)
        })
        _.forEach(elarr, (item, index) => {
          item.className = item.className.concat(flags[index] === 'org' ? teamIcon + team : concatIcon + concat)
        })
      }, 1)
    })
  }
  // 穿缩时更新图标
  public transChange(targetKeys, direction, moveKeys) {
    // 右侧穿缩栏设定
    this.userBinds = targetKeys
    // 右侧穿缩的时候才设置样式
    if (direction === 'right') {
      this.changeRightIcon(moveKeys)
    }
  }
  // 穿梭框左侧节点选择
  public transChecked(records, e, checkedKeys, itemSelect) {
      const { eventKey } = e.node
      itemSelect(eventKey, checkedKeys.indexOf(eventKey) === -1)
  }

  // 穿梭框搜索
  public transSearch(dir: string, value: string) {
    // 搜索框方法 dir:左left右right  value：搜索的值
    const theExpandedKeys = this.dataList.map((e) => {
      const names : string = e.title
      if (names.indexOf(value) > -1) {
        return this.getParentKey(e.key, this.personWithORG)
      }
      return null
    })
    this.autoExpandParent = true
    this.expandedKeys = _.compact(theExpandedKeys)
  }
  // 穿梭框搜索取得父节点算法
  public getParentKey = (key, tree) => {
    let parentKey
    for (const i of tree) {
      if (i.children) {
        if (i.children.some((item) => (item.key + item.flag) === key)) {
          parentKey = i.key + i.flag
        } else if (this.getParentKey(key, i.children)) {
          parentKey = this.getParentKey(key, i.children)
        }
      }
    }
    return parentKey
  }
  // 展开节点
  public transferOnExpand(theExpandedKeys) {
    this.expandedKeys = theExpandedKeys
    this.autoExpandParent = false
  }
  // 树形结构扁平化
  public generateList(data) {
    for (const i of data) {
      this.dataList.push({
        key : i.key + (i.flag === this.personFlag ? this.personFlag : this.orgFlag),
        title : i.title,
        flag : i.flag })
      if (i.children) {
        this.generateList(i.children)
      }
    }
  }
  // 设备名称检证
  public nameInputCheck() {
    if (this.deviceFormData.name) {
      this.valNameHelp = ''
      this.valNameStatus = ''
    } else {
      this.valNameHelp = this.$i18n.tc('device.home_draw.error_name')
      this.valNameStatus = 'error'
    }
  }
  // 提交
  public async DetailSubmit() {
    commitSetLoading(this.$store, true)
    // 设备名称检证
    this.nameInputCheck()
    if (this.valNameStatus !== '') {
      commitSetLoading(this.$store, false)
      return
    }
    // 更新设备基本信息
    const updData = {
      name: this.deviceFormData.name,
      id: this.deviceFormData.id
    }
    await updateDevice(updData, this.theRanterIDs)
    let index = 0
    const personsSort: number[] = []
    const persons = _.compact(_.map(this.userBinds, (e: string) => {
      index = index + 1
      if (_.includes(e, this.personFlag)) {
        personsSort.push(index)
        return parseInt(_.replace(e, this.personFlag, ''), 10)
      }
    }))
    await addBlacklist(this.theRanterIDs, this.deviceFormData.id, persons)
    // 设备数据刷新
    await getTypeDeviceData(this.$store.state.main.userProfile.user_type, this.orgDataId)
    const tempDeviceData: any = this.$store.state.main.deviceHardWare.deviceData
    pageReturnDatas.deviceDataBind = tempDeviceData
    pageReturnDatas.colseFlag = false
    this.$emit('DetailDrawFlag', pageReturnDatas)
    commitSetLoading(this.$store, false)
  }
  // 关闭画面
  public detailClose() {
    pageReturnDatas.colseFlag = false
    this.$emit('DetailDrawFlag', pageReturnDatas)
  }
  // 画面打开时数据取得
  private async created() {
    // 取得设备和人员绑定关系
    await getDeviceWithPersons(this.$store.state.main.userProfile.user_type, this.devicedetailDataID)
    const bondPersonData: any = this.$store.state.main.deviceHardWare.deviceData
    this.transferDataSource = bondPersonData.persons.map((e) => {
      return {
        key: e.id.toString() + this.personFlag,
        title: e.name,
        disabled: false,
      }
    })
    await getDeviceWithPersonsBlacklist( this.devicedetailDataID )
    const blacklist: any = this.$store.state.main.deviceHardWare.blacklist
    this.userBinds = blacklist.map((e) => {
      return e.id.toString() + this.personFlag
    })
    // 设备基本信息
    this.deviceFormData = bondPersonData
    // 设备名字
    this.name = this.deviceFormData.name
    // 图标更新
    this.changeRightIcon(this.userBinds)
  }
}
